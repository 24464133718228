import axios from '../../axios';

let initialPercentage = 0;
let international_freight = 0;
let local_freight = 0;
let sea_charge = 0;
let air_charge = 0;
let custom = 0;
const decrement = 0.0001; // Fixed decrement value
const packaging = 0.01; // Fixed packaging value in percentage %
const paystack = 0.0015 // paystack charges 1.5% + 100
const minRate = 0.04;

await axios
  .get('/get-charges/5')
  .then((response) => {
    const data = response.data.result;
    initialPercentage = parseFloat(data.initial_percentage) || 0;
    international_freight = parseFloat(data.international_freight) || 0;
    local_freight = parseFloat(data.local_freight) || 0;
    sea_charge = parseFloat(data.sea_charge) || 0;
    air_charge = parseFloat(data.air_charge) || 0;
    custom = parseFloat(data.custom) || 0;
  })
  .catch((error) => {
    console.error('Error fetching service charge:', error);
  });

export const ServiceCharge = async ({ price, qty }) => {
  console.log('CalculateServiceWithServiceCharge QUANTITY', qty, price);
  if (qty > 0) {
    const adjustedPercentage = Math.max(
      minRate,
      initialPercentage - decrement * (qty - 1)
    );
    const total = price * adjustedPercentage;

    return { serviceCharge: total };
  }

  return { serviceCharge: 0, total: 0 };
};

export const CalculateServiceWithShipping = async ({
  price,
  qty,
  serviceType,
  selectedService,
  c = 0
}) => {
  if (qty > 0) {
    const calculatedCharge = Math.max(
      minRate,
      initialPercentage - decrement * (qty - 1)
    );

    const serviceCharge = price * calculatedCharge;

    const selfService =
      price * (local_freight + packaging + paystack) + (100) ; // local freight 5% and packaging 1% of total price

    let standardShipping = 0;

    if (serviceType === 'vip') {
      let chargesOfslectedService = air_charge;

      if (selectedService === 'sea') {
        chargesOfslectedService = sea_charge;
      }
      standardShipping =
        price * (international_freight + packaging + chargesOfslectedService + local_freight + custom + paystack) + 100;
    }

	

    return {
      selfService,
      standardShipping,
      serviceCharge,
      sea_charge,
      air_charge,
      custom
    };
  }

  return {
    calculatedTotalSelfService: 0,
    calculatedTotalStandardShipping: 0,
    calculatedTotal: 0,
    sea_charge,
    air_charge,
    custom
  };
};
