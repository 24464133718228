import React from "react";
import { Collapse, Button } from 'react-bootstrap';
import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import './HowToBuy.css'; // Ensure this file is in the same folder

class PrivacyPolicy extends React.Component {
  state = {
    openSection: null, // Keeps track of which section is currently open
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  toggleSection = (section) => {
    this.setState({
      openSection: this.state.openSection === section ? null : section
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="privacy-policy">
          <div className="top-content policy-bg">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-heading">
                    <h1>Privacy Policy</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="policy-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {/* <h2>Our Company Policy</h2> */}

                  {/* Section 1: Introduction */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section1')}
                      aria-controls="section1"
                      aria-expanded={this.state.openSection === 'section1'}
                    >
                      <strong>1. Introduction</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section1'}>
                      <div id="section1" className="policy-content">
                        <p>
                          Welcome to Flyorship ("we," "our," or "us"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.flyorship.com and use our services.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 2: Information We Collect */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section2')}
                      aria-controls="section2"
                      aria-expanded={this.state.openSection === 'section2'}
                    >
                      <strong>2. Information We Collect</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section2'}>
                      <div id="section2" className="policy-content">
                        <h6>2.1 Personal Information</h6>
                        <ul>
                          <li>Legal name</li>
                          <li>Email address</li>
                          <li>Phone number</li>
                          <li>Government-issued identification documents</li>
                          <li>Shipping addresses</li>
                          <li>Payment information</li>
                          <li>Login credentials</li>
                          <li>Communication preferences</li>
                          <li>Social media account information when using OAuth services</li>
                        </ul>
                        <h6>2.2 Automatic Information</h6>
                        <ul>
                          <li>Device information</li>
                          <li>IP address</li>
                          <li>Browser type</li>
                          <li>Operating system</li>
                          <li>Website usage data</li>
                          <li>Search queries</li>
                          <li>Products viewed and saved</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 3: How We Use Your Information */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section3')}
                      aria-controls="section3"
                      aria-expanded={this.state.openSection === 'section3'}
                    >
                      <strong>3. How We Use Your Information</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section3'}>
                      <div id="section3" className="policy-content">
                        <ul>
                          <li>Processing your orders and payments</li>
                          <li>Providing customer support</li>
                          <li>Sending order updates and notifications</li>
                          <li>Verifying your identity</li>
                          <li>Preventing fraud</li>
                          <li>Improving our services</li>
                          <li>Sending marketing communications (with your consent)</li>
                          <li>Complying with legal obligations</li>
                          <li>Analytics and performance monitoring</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 4: Information Sharing */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section4')}
                      aria-controls="section4"
                      aria-expanded={this.state.openSection === 'section4'}
                    >
                      <strong>4. Information Sharing</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section4'}>
                      <div id="section4" className="policy-content">
                        <ul>
                          <li>Shipping and logistics partners</li>
                          <li>Payment processors</li>
                          <li>Product suppliers</li>
                          <li>Government authorities (when required by law)</li>
                          <li>Our service providers and business partners</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 5: Data Security */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section5')}
                      aria-controls="section5"
                      aria-expanded={this.state.openSection === 'section5'}
                    >
                      <strong>5. Data Security</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section5'}>
                      <div id="section5" className="policy-content">
                        <ul>
                          <li>Two-factor authentication (2FA)</li>
                          <li>Multi-factor authentication (MFA)</li>
                          <li>Encryption of sensitive data</li>
                          <li>Regular security audits</li>
                          <li>Secure payment processing</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 6: Your Rights */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section6')}
                      aria-controls="section6"
                      aria-expanded={this.state.openSection === 'section6'}
                    >
                      <strong>6. Your Rights</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section6'}>
                      <div id="section6" className="policy-content">
                        <ul>
                          <li>Access your personal information</li>
                          <li>Correct inaccurate information</li>
                          <li>Request deletion of your information</li>
                          <li>Opt-out of marketing communications</li>
                          <li>Update your preferences</li>
                          <li>Export your data</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 7: Cookies and Tracking */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section7')}
                      aria-controls="section7"
                      aria-expanded={this.state.openSection === 'section7'}
                    >
                      <strong>7. Cookies and Tracking</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section7'}>
                      <div id="section7" className="policy-content">
                        <ul>
                          <li>Maintain your session</li>
                          <li>Remember your preferences</li>
                          <li>Analyze website usage</li>
                          <li>Improve user experience</li>
                          <li>Provide personalized content</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 8: Children's Privacy */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section8')}
                      aria-controls="section8"
                      aria-expanded={this.state.openSection === 'section8'}
                    >
                      <strong>8. Children Privacy</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section8'}>
                      <div id="section8" className="policy-content">
                        <p>
                          Our services are not intended for users under 18 years of age. We do not knowingly collect information from children.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 9: International Data Transfers */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section9')}
                      aria-controls="section9"
                      aria-expanded={this.state.openSection === 'section9'}
                    >
                      <strong>9. International Data Transfers</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section9'}>
                      <div id="section9" className="policy-content">
                        <p>
                          We may transfer your information across borders in compliance with applicable data protection laws.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 10: Changes to Privacy Policy */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section10')}
                      aria-controls="section10"
                      aria-expanded={this.state.openSection === 'section10'}
                    >
                      <strong>10. Changes to Privacy Policy</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section10'}>
                      <div id="section10" className="policy-content">
                        <p>
                          We reserve the right to update this Privacy Policy. We will notify you of any material changes via email or website notice.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 11: Contact Us */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section11')}
                      aria-controls="section11"
                      aria-expanded={this.state.openSection === 'section11'}
                    >
                      <strong>11. Contact Us</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section11'}>
                      <div id="section11" className="policy-content">
                        <ul>
                          <li>Email: flyorship@gmail.com</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;