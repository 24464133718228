import React, { useEffect, useState } from 'react';
import axios from './../../axios';
import Loader from '../../components/Utility/Loader';

const TranslateComponent = ({
  content,
  targetLanguage,
  onTranslationComplete
}) => {
  const [translatedText, setTranslatedText] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const translatedText = async () => {
      if (!content || !targetLanguage) return;

      const url = `/translate`;

      try {
        const response = await axios.post(url, { content, targetLanguage });

        if (response.data.error_code) {
          setError(response.data.error_msg);
        } else {
          setTranslatedText(response.data.trans_result[0].dst);
        }
      } catch (error) {
        setError('Error making API request: ' + error.message);
      }
    };

    translatedText();
  }, [content, targetLanguage]);

  return (
    <div>
      {error && !translatedText ? (
        <p style={{ color: 'red' }}>Error: {error}</p>
      ) : (
        <p style={{fontSize: '12px', textAlign: 'left'}}>
        {translatedText}
        </p>
      )}
    </div>
  );
};

export default TranslateComponent;
