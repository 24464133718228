import React, { useEffect, useState } from 'react';
import axios from './../../../axios';
import Logout from '../../../components/SuperAdmin/Logout';

const SetCharges = () => {
  const [charges, setCharges] = useState({
    initial_percentage: '',
    international_freight: '',
    local_freight: '',
    custom: '',
    sea_charge: '',
    air_charge: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchCharge = async (user_id) => {
      axios
        .get('/get-charges/' + user_id)
        .then((response) => {
          const data = response.data.result;

          

          setCharges({
            initial_percentage: data.initial_percentage,
            international_freight: data.international_freight,
            local_freight: data.local_freight,
            custom: data.custom,
            sea_charge: data.sea_charge,
            air_charge: data.air_charge,
          });
        })
        .catch((error) => {
          console.error('Error getting charges', error);
        });
    };

    if (localStorage.getItem('userId')) {
      fetchCharge(localStorage.getItem('userId'));
    }
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCharges((prev) => ({ ...prev, [id]: value }));
  };

  const validate = () => {
    let newErrors = {};
    if (!charges.initial_percentage)
      newErrors.initial_percentage = 'Initial percentage is required';
    if (!charges.local_freight) newErrors.local_freight = 'Local freight is required';
    if (!charges.international_freight) newErrors.international_freight = 'International freight is required';
    if (!charges.air_charge) newErrors.air_charge = 'Air charge is required';
    if (!charges.sea_charge) newErrors.sea_charge = 'Sea charge is required';
    if (!charges.custom) newErrors.custom = 'Custom freight is required';
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      await axios.post('/set-charges', {
        ...charges,
        user_id: localStorage.getItem('userId')
      });
      alert('Charges submitted successfully');
      setErrors({});
    } catch (error) {
      alert('Error submitting charges');
    }
  };

  return (
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className="bx bx-menu sidebarBtn"></i>
          <span className="dashboard">Set Charges</span>
        </div>
        <div>
          <Logout />
        </div>
      </nav>

      <div className="home-content center-content">
        <div className="sales-boxes">
          <div className="recent-sales box form-container">
            <div className="title">Set Charges</div>

            <form onSubmit={handleSubmit} className='charge-form'>
              {['initial_percentage', 'local_freight', 'international_freight', 'sea_charge', 'air_charge', 'custom'].map(
                (charge) => {
                  const label = charge
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
                    console.log(charges[charge], charge)
                  return (
                    <div className="mb-3" key={charge}>
                      <label htmlFor={charge} className="form-label">
                        {label}
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id={charge}
                        value={charges[charge]}
                        onChange={handleChange}
                      />
                      {errors[charge] && (
                        <div className="text-danger">{errors[charge]}</div>
                      )}
                    </div>
                  );
                }
              )}

              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SetCharges;
