import React, { useEffect, useState } from "react";
import axios from "./axios";

const ApiEndpoints = () => {
  const [endpoints, setEndpoints] = useState([]);

  useEffect(() => {
    const fetchEndpoints = async () => {
      try {
        const response = await axios.get("/callback");

        // Debugging: Check the entire response data structure
        console.log("Fetched data:", response);

        // Ensure we are accessing the 'routes' array directly
        if (response) {
          setEndpoints(response); // Correctly set the routes array to state
        } else {
          console.warn("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching the API endpoints:", error);
      }
    };

    fetchEndpoints();
  }, []);

  return (
    <div>
      <h1>Callback</h1>
      <ul>{endpoints}</ul>
    </div>
  );
};

export default ApiEndpoints;
