import React from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as userActions from '../../../actions/index';
import * as userActionsCart from '../../../actions/account';
import * as AccountActions from '../../../actions/account';
import Loader from '../../../components/Utility/Loader';
import getSymbolFromCurrency from 'currency-symbol-map';
import axios from './../../../axios';
import { Helmet } from 'react-helmet';
import {
  FaShareAlt,
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaEnvelope,
  FaTwitter,
  FaLink
} from 'react-icons/fa';
import { ServiceCharge } from '../../Utility/Charges';
import TranslateComponent from '../../Utility/TranslateComponent';

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carousel_responsive: {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 3 }
      },
      quantity: 1,
      loader: false,
      valid: true,
      like: false,
      product_sku_requirements: [],
      product_detail: {},
      active_properties: {},
      requirements_validation: {},
      Unauthorized: false,
      currencyRate: null,
      redirectCheckout: false,
      redirectToCart: false,
      cartStatus: 'Add to Cart',
      showServiceChargeModal: false,
      showShareDropdown: false,
      service_charge: 0,
      requirementsHandler: this.requirementsHandler,
      selected_properties: []
    };
    this.AddToCart = this.AddToCart.bind(this);
    this.toggleShareDropdown = this.toggleShareDropdown.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.requirementsHandler = this.requirementsHandler(this);
  }

  async componentDidMount() {
    try {
      window.scrollTo(0, 0);
      const currencyRate = localStorage.getItem('currencyRate');
      this.setState({ currencyRate, loader: true });

      const search = this.props.location?.search?.substring(1) || '';
      if (!search) {
        throw new Error('No search parameter provided');
      }

      const response = await this.props.ProductDetailById(search);
      const product_item = response?.response?.data;

      if (product_item) {
        const chargeResponse = await axios.get('/get-charges/' + 5);
        const service_charge = chargeResponse.data?.result?.service_charge || 0;
        const product_data = this.extractProductData(
          product_item,
          service_charge
        );
        ServiceCharge({
          price: product_data.promotion_price,
          qty: 1
        }).then((result) => {
          this.setState({
            service_charge: result.serviceCharge.toFixed(2)
          });
        });
        this.setState({ loader: false, product_detail: product_data });
      } else {
        throw new Error('Product not found');
      }
    } catch (error) {
      console.error('Error in componentDidMount:', error);
      this.setState({ loader: false });
      toast.error(error.message || 'Failed to load product details', {
        position: toast.POSITION.TOP_RIGHT
      });
      window.history.back();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if quantity has changed and update service charge
    if (prevState.quantity !== this.state.quantity) {
      ServiceCharge({
        price: this.state.product_detail.promotion_price,
        qty: this.state.quantity
      }).then((result) => {
        console.log('Service charge updated:', result);
        this.setState({ service_charge: result.serviceCharge.toFixed(2) });
      });
    }

    // Compare selected_properties changes
    const prevPropsArray = prevState.selected_properties;
    const currentPropsArray = this.state.selected_properties;

    const hasChanged =
      prevPropsArray.length !== currentPropsArray.length ||
      prevPropsArray.some(
        (prevItem, index) =>
          JSON.stringify(prevItem) !== JSON.stringify(currentPropsArray[index])
      );

    if (this.state.valid && hasChanged) {
      if (this.state.product_sku_requirements.length > 0) {
        let updatedProduct = null;

        this.state.product_sku_requirements.forEach((item) => {
          if (item.properties.length > 0) {
            let filteredSku = this.state.selected_properties.every(
              (selected) => {
                let [prop_id, value_id] = selected.properties.split(':');
                console.log(
                  'Checking prop_id:',
                  prop_id,
                  'value_id:',
                  value_id
                );

                // Check if the SKU contains at least one matching property
                return item.properties.some(
                  (prop) => prop.prop_id == prop_id && prop.value_id == value_id
                );
              }
            );

            if (filteredSku) {
              updatedProduct = {
                ...this.state.product_detail,
                price: item.price
              };
            }
          }
        });

        // Update state once, if a valid SKU is found
        if (updatedProduct) {
          this.setState({ product_detail: updatedProduct });
        }
      }
    }
  }

  extractProductData(product_item, service_charge) {
    var product_requirements = {};
    const properties = product_item?.multi_language_info?.properties || null;
    const property_image_list = product_item?.property_image_list || null;

    if (properties) {
      for (const property of properties) {
        let key = `${property.prop_id}:${property.value_id}`;

        const imageItem = property_image_list?.find(
          (item) => item.properties === key
        );

        if (!product_requirements[property.prop_name]) {
          product_requirements[property.prop_name] = [];
        }

        const propertyData = {
          properties: key,
          value: property.value_name,
          prop_name: property.prop_name
        };
        if (imageItem) {
          propertyData.url = imageItem.image_url;
        }

        product_requirements[property.prop_name].push(propertyData);
      }

      // **Filter out properties that have only one value**
      Object.keys(product_requirements).forEach((key) => {
        if (product_requirements[key].length <= 1) {
          delete product_requirements[key]; // Remove single-value properties
        }
      });

      product_item.product_requirements = product_requirements;
    }

    const skus = product_item.sku_list || [];

    this.setState({
      product_sku_requirements: skus
    });

    return {
      title:
        product_item?.multi_language_info?.title || product_item?.title || '',
      external_id: product_item?.item_id?.toString() || '',
      external_url: product_item?.detail_url || '',
      product_primary_image: product_item?.pic_urls?.[0] || '',
      service_charges: service_charge || 0,
      orginal_price: product_item?.orginal_price || 0,
      price: product_item?.price || product_item?.orginal_price || 0,
      quantity: this.state.quantity,
      skus: product_item?.skus || {},
      seller_info: product_item?.seller_info || {},
      product_images: product_item?.pic_urls || [],
      promotion_price: (product_item?.promotion_price || 0) / 100,
      product_requirements: product_requirements || {}
    };
  }

  extractProductRequirements(product_item) {
    const product_requirements = {};
    for (const key in product_item.props_list) {
      const [propsListNewKey, propsListKeyValue] =
        product_item.props_list[key].split(':');

      if (!product_requirements[propsListNewKey]) {
        product_requirements[propsListNewKey] = [];
      }
      product_requirements[propsListNewKey].push({
        properties: key,
        value: propsListKeyValue?.trim() || ''
      });
    }
    return product_requirements;
  }

  DecreaseItem = () => {
    this.setState((prevState) => ({
      quantity: Math.max(prevState.quantity - 1, 1)
    }));
  };

  IncrementItem = () => {
    this.setState((prevState) => ({
      quantity: prevState.quantity + 1
    }));
  };

  AddToCart = async (event, item) => {
    if (!this.state.valid || this.state.quantity === 0) {
      toast.error(
        this.state.valid
          ? 'Please enter a valid quantity'
          : 'Please Select Product Requirements Properly',
        { position: toast.POSITION.TOP_CENTER }
      );
      return;
    }

    try {
      const form_data = new FormData();
      form_data.append('quantity', this.state.quantity);
      const product_detail = {
        ...this.state.product_detail,
        quantity: this.state.quantity
      };
      form_data.append('product_detail', JSON.stringify(product_detail));
      form_data.append(
        'product_requirements',
        JSON.stringify(this.state.selected_properties)
      );
      if (item === 'buy_now') {
        form_data.append('clear_cart', '1');
      }

      const response = await this.props.onAddCart(form_data);
      if (response.success) {
        if (item === 'buy_now') {
          this.setState({ redirectCheckout: true });
        } else {
          this.setState({ cartStatus: 'Go to Cart' });
          const cartResponse = await this.props.onCart();
          this.setState({ cartItemCount: cartResponse.data?.length });
        }
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      } else {
        this.handleCartError(response);
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('An error occurred while adding the item to your cart', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  handleCartError(response) {
    if (response.message === 'Unauthorized') {
      toast.error(
        'You are not logged in. Please log in to save items to your shopping cart',
        {
          position: toast.POSITION.TOP_RIGHT
        }
      );
      setTimeout(() => this.setState({ Unauthorized: true }), 6000);
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  }

  AddToWishlist = async (event) => {
    if (!this.state.valid || this.state.quantity === 0) {
      toast.error(
        this.state.valid
          ? 'Please enter a valid quantity'
          : 'Please Select Product Requirements Properly',
        { position: toast.POSITION.TOP_CENTER }
      );
      return;
    }

    try {
      const form_data = new FormData();
      form_data.append('quantity', this.state.quantity);
      const product_detail = {
        ...this.state.product_detail,
        quantity: this.state.quantity
      };
      form_data.append('product_detail', JSON.stringify(product_detail));
      form_data.append('product_requirements', this.state.selected_properties);

      const response = await this.props.onWishList(form_data);
      if (response.success) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        this.setState({ like: true });
        const wishlistResponse = await this.props.onGetWishlist();
        this.setState({ whishListItemCount: wishlistResponse?.length });
      } else {
        this.handleWishlistError(response);
      }
    } catch (error) {
      console.error('Error adding to wishlist:', error);
      toast.error('An error occurred while adding to wishlist', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  handleWishlistError(response) {
    if (response.message === 'Unauthorized') {
      toast.error(
        'You still not login, please login to save item to your shopping cart',
        {
          position: toast.POSITION.TOP_RIGHT
        }
      );
      setTimeout(() => this.setState({ Unauthorized: true }), 6000);
    } else {
      toast.error(response.message || 'No results found', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  requirementsHandler = (item, properties_name) => {
    const active_properties = { ...this.state.active_properties };

    if (item?.url) {
      const product_detail = {
        ...this.state.product_detail,
        product_primary_image: item.url
      };
      this.setState({ product_detail });
    }

    this.setState((prevState) => {
      let selectedProperties = Array.isArray(prevState.selected_properties)
        ? [...prevState.selected_properties] // Create a shallow copy to avoid direct mutation
        : [];

      // Check if the prop_name exists
      const existingIndex = selectedProperties.findIndex(
        (prop) => prop.prop_name === item.prop_name
      );

      if (existingIndex !== -1) {
        // Replace existing property if the value is different
        selectedProperties[existingIndex] = {
          properties: item.properties,
          prop_name: item.prop_name
        };
      } else {
        // Add new property if it doesn't exist
        selectedProperties.push({
          properties: item.properties,
          prop_name: item.prop_name
        });
      }

      return { selected_properties: selectedProperties };
    });

    active_properties[properties_name] = item.properties;

    const requirements_validation = {
      ...this.state.requirements_validation,
      [properties_name]: true
    };
    const valid = Object.keys(
      this.state.product_detail.product_requirements || {}
    ).every((key) => key in requirements_validation);

    this.setState({ active_properties, requirements_validation, valid });
  };

  handleQuantityChange = (e) => {
    const value = e.target.value;
    if (
      value === '' ||
      (!isNaN(value) && value > 0 && Number.isInteger(Number(value)))
    ) {
      this.setState({ quantity: parseInt(value) || 1 });
    }
  };

  handleShowServiceChargeModal = () =>
    this.setState({ showServiceChargeModal: true });
  handleCloseServiceChargeModal = () =>
    this.setState({ showServiceChargeModal: false });

  toggleShareDropdown() {
    this.setState((prevState) => ({
      showShareDropdown: !prevState.showShareDropdown
    }));
  }

  handleShare(platform) {
    const { product_detail } = this.state;
    const productUrl = window.location.href;
    const productTitle = product_detail?.title || 'Check out this product';
    const productImage = product_detail?.product_primary_image?.startsWith(
      'http'
    )
      ? product_detail.product_primary_image
      : `${window.location.origin}${
          product_detail?.product_primary_image || ''
        }`;
    let shareUrl;

    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          productUrl
        )}`;
        break;
      case 'instagram':
        this.copyToClipboard(
          `${productTitle}\n${productUrl}\nImage: ${productImage}`
        );
        alert(
          'Link and image URL copied! Paste it in your Instagram post or story.'
        );
        return;
      case 'tiktok':
        this.copyToClipboard(
          `${productTitle}\n${productUrl}\nImage: ${productImage}`
        );
        alert(
          'Link and image URL copied! Paste it in your TikTok video description.'
        );
        return;
      case 'email':
        shareUrl = `mailto:?subject=${encodeURIComponent(
          productTitle
        )}&body=${encodeURIComponent(
          `${productTitle}\n${productUrl}\nImage: ${productImage}`
        )}`;
        break;
      case 'x':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          productUrl
        )}&text=${encodeURIComponent(productTitle)}`;
        break;
      case 'copy':
        this.copyToClipboard(
          `${productTitle}\n${productUrl}\nImage: ${productImage}`
        );
        toast.success('Link and image URL copied to clipboard!', {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      default:
        return;
    }

    window.open(shareUrl, '_blank', 'width=600,height=400');
    this.setState({ showShareDropdown: false });
  }

  copyToClipboard(text) {
    navigator.clipboard.writeText(text).catch((err) => {
      console.error('Failed to copy to clipboard:', err);
      toast.error('Failed to copy link', {
        position: toast.POSITION.TOP_RIGHT
      });
    });
  }

  render() {
    const {
      redirectCheckout,
      Unauthorized,
      quantity,
      product_detail,
      loader,
      cartStatus,
      like,
      currencyRate,
      showServiceChargeModal,
      showShareDropdown,
      service_charge,
      active_properties,
      requirements_validation,
      requirementsHandler,
      product_sku_requirements
    } = this.state;

    if (this.state.redirectToCart) {
      return <Navigate to="/cart" />;
    }
    if (redirectCheckout) {
      return <Navigate to="/checkout" />;
    }
    if (Unauthorized) {
      return <Navigate to="/login" />;
    }
    if (loader) {
      return <Loader />;
    }

    const pageUrl = window.location.href;
    const pageTitle = product_detail?.title || 'Product Page';
    const pageImage = product_detail?.product_primary_image?.startsWith('http')
      ? product_detail.product_primary_image
      : `${window.location.origin}${
          product_detail?.product_primary_image ||
          'https://flyorship.com/assets/images/site-logo.png'
        }`;
    const pageDescription = `Check out this product: ${
      product_detail?.title || ''
    } priced at ${getSymbolFromCurrency('NGN')}${parseFloat(
      (product_detail?.promotion_price || 0) * (currencyRate || 1)
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;

    console.log(
      'Product product_sku_requirements -------------------:',
      product_sku_requirements
    ); // Debug log

    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
          {/* Ensure static tags are overridden */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:type" content="product" />
          <meta property="og:url" content={pageUrl} />
          <meta property="og:image" content={pageImage} />
          <meta property="og:description" content={pageDescription} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
          <meta name="twitter:image" content={pageImage} />
        </Helmet>

        <div className="product-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                {product_detail?.product_primary_image && (
                  <div
                    className="swiper mySwiper2"
                    style={{ position: 'relative' }}
                  >
                    <img
                      src={product_detail.product_primary_image}
                      alt="product"
                      style={{ width: '100%' }}
                      onError={(e) => {
                        e.target.src = '/path/to/fallback-image.jpg'; // Replace with your fallback image path
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 10
                      }}
                    >
                      <button
                        className="btn btn-light"
                        onClick={this.toggleShareDropdown}
                        style={{
                          backgroundColor: '#fff',
                          borderRadius: '50%',
                          width: '40px',
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                      >
                        <FaShareAlt />
                      </button>

                      {showShareDropdown && (
                        <div
                          className="share-dropdown"
                          style={{
                            position: 'absolute',
                            top: '50px',
                            right: '0',
                            backgroundColor: '#fff',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '5px',
                            padding: '5px 0',
                            minWidth: '150px'
                          }}
                        >
                          <button
                            className="btn btn-link share-option"
                            onClick={() => this.handleShare('facebook')}
                          >
                            <FaFacebookF className="share-icon" /> Facebook
                          </button>
                          <button
                            className="btn btn-link share-option"
                            onClick={() => this.handleShare('instagram')}
                          >
                            <FaInstagram className="share-icon" /> Instagram
                          </button>
                          <button
                            className="btn btn-link share-option"
                            onClick={() => this.handleShare('tiktok')}
                          >
                            <FaTiktok className="share-icon" /> TikTok
                          </button>
                          <button
                            className="btn btn-link share-option"
                            onClick={() => this.handleShare('email')}
                          >
                            <FaEnvelope className="share-icon" /> Email
                          </button>
                          <button
                            className="btn btn-link share-option"
                            onClick={() => this.handleShare('x')}
                          >
                            <FaTwitter className="share-icon" /> X
                          </button>
                          <button
                            className="btn btn-link share-option"
                            onClick={() => this.handleShare('copy')}
                          >
                            <FaLink className="share-icon" /> Copy Link
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="product-slider">
                  {product_detail?.product_images?.length > 0 && (
                    <Carousel responsive={this.state.carousel_responsive}>
                      {product_detail.product_images.map((imgSrc, index) => (
                        <div
                          key={index}
                          onClick={() =>
                            this.setState({
                              product_detail: {
                                ...product_detail,
                                product_primary_image: imgSrc
                              }
                            })
                          }
                        >
                          <img src={imgSrc} alt="product" />
                        </div>
                      ))}
                    </Carousel>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="product-detail">
                  <h1>{product_detail?.title || 'Product Title'}</h1>

                  <h6 className="mb-3">
                    {getSymbolFromCurrency('NGN')}
                    {parseFloat(
                      (product_detail?.price || 0) * (currencyRate || 1)
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </h6>
                  <h5 className="mb-3">
                    <span
                      onClick={this.handleShowServiceChargeModal}
                      style={{
                        cursor: 'pointer',
                        color: '#124e75'
                      }}
                    >
                      Service charges
                    </span>
                    : ₦
                    {parseFloat(
                      (service_charge || 0) * (currencyRate || 1)
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </h5>

                  <div className="quantity-btn btn-added">
                    <p>Qty</p>
                    <input
                      type="button"
                      className="minus"
                      value="-"
                      onClick={this.DecreaseItem}
                    />
                    <input
                      type="number"
                      className="input-text qty text"
                      value={quantity}
                      onChange={this.handleQuantityChange}
                      min="1"
                    />
                    <input
                      type="button"
                      className="plus"
                      value="+"
                      onClick={this.IncrementItem}
                    />
                  </div>

                  {/* {product_detail?.product_requirements &&
                    Object.keys(product_detail.product_requirements).map(
                      (key) => (
                        <div
                          key={key}
                          className={
                            this.state.requirements_validation[key]
                              ? 'panel panel-default'
                              : 'panel panel-danger'
                          }
                        >
                          <div className="panel-heading">
                            <h3 className="panel-title">{key}</h3>
                          </div>
                          <div className="panel-body">
                            {product_detail.product_requirements[key].map(
                              (item) => (
                                <button
                                  key={item.properties}
                                  onClick={() =>
                                    this.requirementsHandler(item, key)
                                  }
                                >
                                  {item.value}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      )
                    )} */}

                  {product_detail?.product_requirements != null &&
                    Object.keys(product_detail.product_requirements).map(
                      function (key, index) {
                        return (
                          <div
                            className={
                              requirements_validation[key]
                                ? 'panel panel-default'
                                : 'panel panel-danger'
                            }
                          >
                            <div className="panel-heading">
                              <h3 className="panel-title">
                                <TranslateComponent
                                  content={key}
                                  targetLanguage={'en'}
                                />
                              </h3>
                            </div>
                            <div className="panel-body">
                              {product_detail.product_requirements[key].map(
                                (item, itemIndex) =>
                                  item?.url ? (
                                    <img
                                      src={item.url}
                                      alt="props_img"
                                      className={
                                        product_detail.product_primary_image ===
                                        item.url
                                          ? 'active_propertie img-thumbnail'
                                          : 'img-thumbnail'
                                      }
                                      width="50"
                                      onClick={() =>
                                        requirementsHandler(item, key)
                                      }
                                    />
                                  ) : (
                                    item?.url === undefined && (
                                      <button
                                        type="button"
                                        className={
                                          active_properties[key] ===
                                          item.properties
                                            ? 'active_propertie btn btn-default'
                                            : 'btn btn-default'
                                        }
                                        onClick={() =>
                                          requirementsHandler(item, key)
                                        }
                                      >
                                        {item.value}
                                      </button>
                                    )
                                  )
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
                <div className="product-btn">
                  <button
                    className="btn btn-primary buy-now"
                    onClick={(event) => this.AddToCart(event, 'buy_now')}
                  >
                    <i className="fa-solid fa-bag-shopping"></i> Buy Now
                  </button>
                  <button
                    className="btn btn-primary cart"
                    onClick={(event) => this.AddToCart(event, 'add_to_cart')}
                  >
                    <img src="/assets/images/cart-img.png" alt="cart" />{' '}
                    {cartStatus}
                  </button>
                  <button
                    className="btn btn-primary wish"
                    onClick={this.AddToWishlist}
                  >
                    <i
                      className={like ? 'fa fa-heart like' : 'fa-heart dislike'}
                      aria-hidden="true"
                    ></i>{' '}
                    Add To Wishlist
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showServiceChargeModal && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000
            }}
            onClick={this.handleCloseServiceChargeModal}
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '10px',
                maxWidth: '400px',
                width: '90%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <p style={{ fontSize: '16px', margin: '12px 0' }}>
                Service charges cover the costs of processing your order,
                including packaging, handling, and customer support. These fees
                ensure a smooth and reliable shopping experience from order
                placement to delivery.
              </p>
              <button
                onClick={this.handleCloseServiceChargeModal}
                style={{
                  backgroundColor: '#124e75',
                  color: '#fff',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth?.loader,
    error: state.Auth?.error,
    products: state.Product
  };
}

const mapDispatchToProps = {
  onCart: userActionsCart.GetCart,
  onAddCart: userActions.AddCart,
  onWishList: AccountActions.AddWishlist,
  onGetWishlist: userActions.GetWishlist,
  ProductDetailById: userActions.ProductDetailById
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
