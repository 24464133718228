import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as Actions from '../../../actions';
import Loader from '../../../components/Utility/Loader';
import LoginHeader from '../../../components/LoginHeader';
import LoginFooter from '../../../components/LoginFooter';
import Validations from '../../Utility/billingValidations';
import { PaystackButton } from 'react-paystack';
import getSymbolFromCurrency from 'currency-symbol-map';
import * as userActions from '../../../actions/index';
import { CalculateServiceWithShipping } from '../../Utility/Charges';

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceType: 'vip',
      selectedService: 'sea',
      shipping_charge: 0,
      air_service_charge: 0,
      sea_service_charge: 0,
      custom_charge: 0,
      total_quantity: 0,
      subtotal: 0,
      billing_form: {
        first_name: {
          type: 'text',
          label: 'Full name *',
          value: '',
          valid: true,
          error_msg: '',
          onBlur_out: false,
          placeholder: 'First name',
          validations: { required: true }
        },
        last_name: {
          type: 'text',
          label: 'Last name *',
          value: '',
          valid: true,
          error_msg: '',
          onBlur_out: false,
          placeholder: 'Last name',
          validations: { required: true }
        },
        email: {
          type: 'email',
          label: 'Email',
          value: '',
          valid: true,
          error_msg: '',
          onBlur_out: false,
          placeholder: 'Enter your email',
          validations: { required: true, email: true }
        },
        address1: {
          type: 'text',
          label: '123 main st',
          value: '',
          valid: true,
          error_msg: '',
          onBlur_out: false,
          placeholder: '123 main st',
          validations: { required: true }
        },
        address2: {
          type: 'text',
          label: '123 main st',
          value: '',
          valid: true,
          error_msg: '',
          onBlur_out: false,
          placeholder: '123 main st',
          validations: { required: false }
        },
        country: {
          value: '',
          valid: true,
          error_msg: '',
          onBlur_out: false,
          validations: { required: true }
        },
        state: {
          value: '',
          valid: true,
          error_msg: '',
          onBlur_out: false,
          validations: { required: true }
        },
        zip: {
          type: 'text',
          label: '789045',
          value: '',
          valid: true,
          error_msg: '',
          onBlur_out: false,
          placeholder: '345354',
          validations: { required: true, zip: true }
        },
        id: {
          type: '',
          label: '',
          value: ''
        }
      },
      products: [],
      countries: [],
      States: [],
      checkoutTotal: null,
      quantity: null,
      checkoutData: null,
      form_valid: false,
      form_data: null,
      ShippingChecked: false,
      SaveChecked: false,
      key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
      reference: new Date().getTime().toString(),
      formSubmitted: false,
      currencyRate: null,
      whishListItemCount: null,
      ApiCurrency: getSymbolFromCurrency('RMB'),
      ConversionCurrency: getSymbolFromCurrency('NGN'),
      states_none: 'block',
      address: null,
      shippingData: {
        rate_id: '',
        carrier_id: '',
        service_code: ''
      },
      user_info: null,
      userEmailValid: false,
      userEmailOnBlurOut: false,
      promoCode: '',
      promoCodeMessage: '',
      serviceCharge: 0,
      totalQuantity: 0,
      totalPrice: 0,
      shippingCharge: 0,
      total: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.applyCoupon = this.applyCoupon.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove('dashboard-open');
    window.scrollTo(0, 0);
    this.props.onGetAuthUser().then((response) => {
      if (response.success === true) {
        this.setState({
          user_info: response.user_info
        });
      }
    });
  }

  componentWillMount() {
    this.setState({ currencyRate: localStorage.getItem('currencyRate') });

    this.props.onCart().then((response) => {
      const userEmail = response?.user_info?.email;
      const userId = response?.user_info?.id;
      const service_charge = response?.service_charges?.service_charge;

      this.setState({ userId: userId });
      this.setState({
        sea_service_charge: response?.service_charges?.sea_service || 0
      });
      this.setState({
        air_service_charge: response?.service_charges?.air_service || 0
      });

      this.setState({ userEmail: userEmail });
      const username = userEmail ? userEmail.split('@')[0] : '';
      const updatedBillingStates = {
        first_name: {
          value: username
        },
        last_name: {
          value: username
        },
        email: {
          value: userEmail
        }
      };
      if (response?.user_info?.id) {
        updatedBillingStates.id = {
          value: response.user_info.id
        };
      }
      this.setState((prevState) => ({
        billing_form: {
          ...prevState.billing_form,
          ...updatedBillingStates
        }
      }));

      if (Array.isArray(response.data)) {
        const totalQuantity = response.data.reduce(
          (sum, product) => sum + product.quantity,
          0
        );
        const airServiceCharge = parseFloat(
          response?.service_charges?.air_service
        ).toFixed(2);

        this.setState({
          total_quantity: totalQuantity,
          products: response.data,
          countries: response.countries,
          checkoutData: parseFloat(
            response.data
              .reduce(
                (total, item) =>
                  total +
                  item.price *
                    item.quantity *
                    localStorage.getItem('currencyRate'),
                0
              )
              .toFixed(2)
          ),
          totalQuantity: response?.data?.reduce(
            (total, item) => total + item.quantity,
            0
          ),
          serviceCharge: service_charge * totalQuantity,
          subtotal:
            parseFloat(
              response.data
                .reduce(
                  (total, item) =>
                    total +
                    item.price *
                      item.quantity *
                      localStorage.getItem('currencyRate'),
                  0
                )
                .toFixed(2)
            ) +
            service_charge * totalQuantity +
            airServiceCharge * totalQuantity,
          currencyRate: localStorage.getItem('currencyRate'),
          user_info: response.user_info
        });
      } else {
        console.error('Invalid cart data structure:', response.data);
      }
    });

    this.props.onWishlist().then((response) => {
      if (response?.message == 'Unauthorized') {
        this.setState({
          user_info: false
        });
      } else {
        this.setState({
          whishListItemCount: response.length,
          user_info: response.user_info
        });
      }
    });

    this.props.getAddress().then((response) => {
      if (response?.data?.length > 0) {
        const updatedBillingStates = {
          first_name: {
            value: response?.data[0].full_name.split(' ')[0]
          },
          last_name: {
            value: response?.data[0].full_name.split(' ')[1]
          },
          email: {
            value: response?.data[0].email
          },
          address1: {
            value: response?.data[0].address
          },
          address2: {
            value: response?.data[0].address1
          },
          zip: {
            value: response?.data[0].zip
          },
          id: {
            value: response?.data[0].id
          }
        };

        this.setState((prevState) => ({
          billing_form: {
            ...prevState.billing_form,
            ...updatedBillingStates
          }
        }));
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.serviceType !== this.state.serviceType ||
      prevState.selectedService !== this.state.selectedService ||
      prevState.checkoutData !== this.state.checkoutData
    ) {
      this.CalculateServiceCharge();
    }
  }

  inputChangeHandler = async (event, identifier) => {
    const update_billing_form = { ...this.state.billing_form };
    const form_element = { ...update_billing_form[identifier] };

    form_element.value = event.target.value;

    if (identifier === 'country') {
      const selectedOption = event.target.options[event.target.selectedIndex];
      form_element.value = selectedOption.value;
      const selectedCountryCode = selectedOption.getAttribute('currency_code');
      const form_data = new FormData();
      form_data.append('country_code', selectedCountryCode);
      await this.props.onState(form_data).then((response) => {
        if (response.states.length == 0) {
          this.setState((prevState) => ({
            billing_form: {
              ...prevState.billing_form,
              state: {
                ...prevState.billing_form.state,
                validations: { required: false }
              }
            },
            states_none: 'none'
          }));
        } else {
          this.setState((prevState) => ({
            billing_form: {
              ...prevState.billing_form,
              state: {
                ...prevState.billing_form.state,
                validations: { required: true }
              }
            },
            States: response.states,
            states_none: 'block'
          }));
        }
      });
    }

    let validate_element = Validations(event, form_element);
    form_element.valid = validate_element.valid;
    form_element.error_msg = validate_element.error_msg;

    update_billing_form[identifier] = form_element;

    this.setState({ billing_form: update_billing_form });
  };

  SubmitHandler = (event) => {
    event.preventDefault();
    let is_form_valid = true;
    const update_billing_form = this.state.billing_form;
    for (let key in update_billing_form) {
      let form_element = update_billing_form[key];
      let validate_element = Validations(event, form_element);

      is_form_valid = validate_element.valid === false ? false : is_form_valid;
      form_element.onBlur_out = true;
      form_element.valid = validate_element.valid;
      form_element.error_msg = validate_element.error_msg;

      update_billing_form[key] = form_element;
    }
    this.setState({ billing_form: update_billing_form });

    if (is_form_valid) {
      const form_data = new FormData();

      this.setState({ form_valid: true });

      for (let key in this.state.billing_form) {
        form_data.append(key, this.state.billing_form[key].value);
      }

      this.setState({ form_data: form_data });

      this.props.onAddress(form_data).then((response) => {
        toast.success(response, {
          position: toast.POSITION.TOP_CENTER
        });
      });
    }
  };

  handleSuccess = (reference) => {
    var orderDetails = this.state.form_data;
    orderDetails.append('status', reference?.status);
    orderDetails.append('shipping_service', this.state.serviceType);
    orderDetails.append('selected_service', this.state.selectedService);
    orderDetails.append('selected_charge', this.state.serviceCharge);
    orderDetails.append('subtotal_price', this.state.total);

    const { rate_id, carrier_id, service_code } = this.state.shippingData;
    orderDetails.append('rate_id', rate_id);
    orderDetails.append('carrier_id', carrier_id);
    orderDetails.append('service_code', service_code);

    this.props.onCheckoutSub(orderDetails).then((response) => {
      if (response.success === true) {
        toast.success(response.message, {
          position: toast.POSITION.TOP_CENTER
        });

        setTimeout(() => {
          this.setState({
            isAuthenticated: true,
            redirect_url: '/',
            formSubmitted: true
          });
        }, 1000);
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    });
  };

  onClose = () => {
    console.log('closed');
  };

  selectState = (code) => {
    const form_data = new FormData();
    form_data.append('country_code', code);
    this.props.onState(form_data).then((response) => {
      this.setState({
        States: response.states
      });
    });
  };

  handleServiceTypeChange = (event) => {
    this.setState({ serviceType: event.target.value });
    const location = this.state.products[0]?.location;
    const seller = this.state.products[0]?.seller;
    const form_data = new FormData();
    for (let key in this.state.billing_form) {
      form_data.append(key, this.state.billing_form[key].value);
    }
    const { products, checkoutData } = this.state;
    form_data.append('quantity', products.length);
    form_data.append('amount', checkoutData);
    form_data.append('location', location);
    form_data.append('seller', seller);

    if (event.target.value === 'vip') {
      form_data.append('shipping_type', 'air');

      this.props
        .ShippingCost(form_data)
        .then((response) => {
          let { rate_id, carrier_id, service_code } = response.response;

          this.setState({
            shippingData: {
              rate_id,
              carrier_id,
              service_code
            }
          });
        })
        .catch((error) => console.error(error));
    }
  };

  handleChange(event) {
    this.setState({ selectedService: event.target.value });
    const form_data = new FormData();

    for (let key in this.state.billing_form) {
      form_data.append(key, this.state.billing_form[key].value);
    }
    if (event.target.value === 'air') {
      form_data.append('shipping_type', 'air');
    } else {
      form_data.append('shipping_type', 'ground');
    }
    const { products, checkoutData } = this.state;

    form_data.append('quantity', products.length);
    form_data.append('amount', checkoutData);
    form_data.append('location', products[0].location);
    form_data.append('seller', products[0].seller);

    this.props
      .ShippingCost(form_data)
      .then((response) => {
        this.setState({
          shippingData: {
            rate_id: response.response.rate_id,
            carrier_id: response.response.carrier_id,
            service_code: response.response.service_code
          }
        });
      })
      .catch((error) => console.error('error'));
  }

  applyCoupon = async (event) => {
    event.preventDefault();

    const couponCode = this.state.promoCode;
    const productPrice = this.state.checkoutData;
    const currency = 'USD';

    const { userId } = this.state;

    try {
      const response = await fetch('/ApplyCoupon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          couponName: couponCode,
          productPrice,
          currency,
          userId
        })
      });

      const data = await response.json();

      if (response.ok) {
        if (data && data.data && data.data.discountedPrice) {
          this.setState({
            checkoutData: data.data.discountedPrice,
            promoCodeMessage: data.message
          });
          toast.success(data.message, {
            position: toast.POSITION.TOP_CENTER
          });
        } else {
          toast.error('Invalid response from the server.', {
            position: toast.POSITION.TOP_CENTER
          });
        }
      } else {
        toast.error(data.message || 'Error applying coupon.', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
      toast.error('Error applying coupon. Please try again later.', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  CalculateServiceCharge = () => {
    const { checkoutData, totalQuantity, serviceType, selectedService } = this.state;
    CalculateServiceWithShipping({
      price: checkoutData,
      qty: totalQuantity,
      serviceType,
      selectedService
    }).then((result) => {
      console.log('CHeckout service result', result);
      let calculateShippingCharge =
        serviceType === 'vip'
          ? result.standardShipping + parseFloat(result.custom)
          : result.selfService;
      this.setState((prevState) => {
        const shippingCharge = parseFloat(calculateShippingCharge) || 0;
        const subtotalPrice = parseFloat(checkoutData) || 0;
        const serviceCharge = parseFloat(result.serviceCharge) || 0;
        const customCharge = parseFloat(result.custom) || 0;
        const airServiceCharge = parseFloat(result.air_charge) || 0;
        const seaServiceCharge = parseFloat(result.sea_charge) || 0;

        return {
          serviceCharge,
          total: shippingCharge + subtotalPrice + serviceCharge,
          shipping_charge: shippingCharge,
          air_service_charge: airServiceCharge,
          sea_service_charge: seaServiceCharge,
          custom_charge: customCharge
        };
      });
    });
  };

  render() {
    const {
      serviceType,
      subtotal,
      selectedService,
      serviceCharge,
      shipping_charge,
      products,
      checkoutData,
      billing_form,
      key,
      form_valid,
      custom_charge,
      air_service_charge,
      sea_service_charge,
      currencyRate,
      ConversionCurrency,
      countries,
      States,
      states_none,
      userEmail,
      total
    } = this.state;

    const { location } = this.props;
    const itemLocation = location;
    const username = userEmail ? userEmail.split('@')[0] : '';
    return (
      <React.Fragment>
        {this.state.loader ? <Loader /> : null}
        {this.state.isAuthenticated ? (
          <Navigate to={this.state.redirect_url} />
        ) : null}
        <div className="container checkout">
          <div className="py-5 text-center">
            <h2>Checkout</h2>
          </div>
          <div className="row">
            <div className="col-md-4 order-md-2 mb-4 mt-2">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Your cart</span>
                <span className="badge badge-secondary badge-pill">3</span>
              </h4>

              <ul className="list-group mb-3">
                {products.map((value, product_key) => (
                  <li
                    className="list-group-item d-flex justify-content-between lh-condensed"
                    key={product_key}
                  >
                    <div>
                      <h6 className="my-0">Product name</h6>
                      {value.product_name}
                    </div>
                    <span className="text-muted">
                      {ConversionCurrency}
                      {parseFloat(
                        value.price * currencyRate
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                      <div style={{ textAlign: 'center', display: 'block' }}>
                        x {value.quantity}
                      </div>
                    </span>
                  </li>
                ))}
                <li className="list-group-item d-flex justify-content-between">
                  <span>Subtotal </span>
                  <strong>
                    {ConversionCurrency}
                    {parseFloat(checkoutData).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Service Charge </span>
                  <strong>
                    {ConversionCurrency}
                    {parseFloat(serviceCharge).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </strong>
                </li>
                {shipping_charge ? (
                  <li className="list-group-item d-flex justify-content-between">
                    <span>Shipping Charge </span>
                    <strong>
                      {ConversionCurrency}
                      {parseFloat(shipping_charge).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </strong>
                  </li>
                ) : (
                  ''
                )}
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total </span>
                  <strong>
                    {ConversionCurrency}
                    {parseFloat(total).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </strong>
                </li>
              </ul>

              <form className="card p-2" onSubmit={this.applyCoupon}>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Promo code"
                    value={this.state.promoCode}
                    onChange={(e) =>
                      this.setState({ promoCode: e.target.value })
                    }
                  />
                  <div className="input-group-append">
                    <button type="submit" className="btn btn-secondary">
                      Redeem
                    </button>
                  </div>
                </div>
                {this.state.promoCodeMessage && (
                  <div className="mt-2 text-success">
                    {this.state.promoCodeMessage}
                  </div>
                )}
              </form>
            </div>
            <div className="col-md-8 order-md-1">
              <form className="needs-validation" onClick={this.SubmitHandler}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="firstName">First name</label>
                    <input
                      type={billing_form.first_name.type}
                      placeholder={billing_form.first_name.placeholder}
                      className="form-control "
                      value={
                        billing_form.first_name.value
                          ? billing_form.first_name.value
                          : ''
                      }
                      onChange={(event) =>
                        this.inputChangeHandler(event, 'first_name')
                      }
                      onBlur={(event) => {
                        billing_form.first_name.onBlur_out = true;
                        this.setState({
                          billing_form: billing_form
                        });
                      }}
                    />
                    {!billing_form.first_name.valid &&
                      billing_form.first_name.onBlur_out && (
                        <div className="error field-error">
                          {billing_form.first_name.error_msg}
                        </div>
                      )}
                    <div className="invalid-feedback">
                      Valid first name is required.
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="lastName">Last name</label>
                    <input
                      type={billing_form.last_name.type}
                      placeholder={billing_form.last_name.placeholder}
                      className="form-control "
                      value={
                        billing_form.last_name.value
                          ? billing_form.last_name.value
                          : ''
                      }
                      onChange={(event) =>
                        this.inputChangeHandler(event, 'last_name')
                      }
                      onBlur={(event) => {
                        billing_form.last_name.onBlur_out = true;
                        this.setState({
                          billing_form: billing_form
                        });
                      }}
                    />
                    {!billing_form.last_name.valid &&
                      billing_form.last_name.onBlur_out && (
                        <div className="error field-error">
                          {billing_form.last_name.error_msg}
                        </div>
                      )}
                    <div className="invalid-feedback">
                      Valid last name is required.
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="email">Email</label>
                  <input
                    type={billing_form.email.type}
                    placeholder={billing_form.email.placeholder}
                    className="form-control "
                    value={userEmail}
                    onChange={(event) =>
                      this.inputChangeHandler(event, 'email')
                    }
                    onBlur={(event) => {
                      billing_form.last_name.onBlur_out = true;
                      this.setState({
                        billing_form: billing_form
                      });
                    }}
                  />
                  {!billing_form.email.valid &&
                    billing_form.email.onBlur_out && (
                      <div className="error field-error">
                        {billing_form.email.error_msg}
                      </div>
                    )}
                  <div className="invalid-feedback">
                    Please enter a valid email address for shipping updates.
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="address">Address</label>
                  <input
                    type={billing_form.address1.type}
                    placeholder={billing_form.address1.placeholder}
                    className="form-control "
                    value={
                      billing_form.address1.value
                        ? billing_form.address1.value
                        : ''
                    }
                    onChange={(event) =>
                      this.inputChangeHandler(event, 'address1')
                    }
                    onBlur={(event) => {
                      billing_form.last_name.onBlur_out = true;
                      this.setState({
                        billing_form: billing_form
                      });
                    }}
                  />
                  {!billing_form.address1.valid &&
                    billing_form.address1.onBlur_out && (
                      <div className="error field-error">
                        {billing_form.address1.error_msg}
                      </div>
                    )}
                  <div className="invalid-feedback">
                    Please enter your shipping address.
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="address2">
                    Address 2 <span className="text-muted ">(Optional)</span>
                  </label>
                  <input
                    type={billing_form.address2.type}
                    placeholder={billing_form.address2.placeholder}
                    className="form-control "
                    value={
                      billing_form.address2.value
                        ? billing_form.address2.value
                        : ''
                    }
                    onChange={(event) =>
                      this.inputChangeHandler(event, 'address2')
                    }
                    onBlur={(event) => {
                      billing_form.last_name.onBlur_out = true;
                      this.setState({
                        billing_form: billing_form
                      });
                    }}
                  />
                  {!billing_form.address2.valid &&
                    billing_form.address2.onBlur_out && (
                      <div className="error field-error">
                        {billing_form.address2.error_msg}
                      </div>
                    )}
                </div>

                <div className="row">
                  <div
                    className={
                      states_none == 'none' ? 'col-md-9 mb-3' : 'col-md-5 mb-3'
                    }
                  >
                    <label htmlFor="country">Country</label>
                    <select
                      className="custom-select d-block w-100"
                      id="country"
                      value={
                        billing_form.country.value
                          ? billing_form.country.value
                          : ''
                      }
                      onChange={(event) =>
                        this.inputChangeHandler(event, 'country')
                      }
                      onBlur={(event) => {
                        billing_form.last_name.onBlur_out = true;
                        this.setState({
                          billing_form: billing_form
                        });
                      }}
                    >
                      <option value="">Choose...</option>
                      {countries.map((value, product_key) => (
                        <option
                          value={value.country_name}
                          currency_code={value.country_code}
                          key={product_key}
                        >
                          {value.country_name}
                        </option>
                      ))}
                    </select>
                    {!billing_form.country.valid &&
                      billing_form.country.onBlur_out && (
                        <div className="error field-error">
                          {billing_form.country.error_msg}
                        </div>
                      )}
                    <div className="invalid-feedback">
                      Please select a valid country.
                    </div>
                  </div>
                  <div
                    className="col-md-4 mb-3"
                    style={{
                      display: states_none === 'block' ? 'block' : 'none'
                    }}
                  >
                    <label htmlFor="state">State</label>
                    <select
                      className="custom-select d-block w-100"
                      value={
                        billing_form.state.value ? billing_form.state.value : ''
                      }
                      onChange={(event) =>
                        this.inputChangeHandler(event, 'state')
                      }
                      onBlur={(event) => {
                        billing_form.last_name.onBlur_out = true;
                        this.setState({
                          billing_form: billing_form
                        });
                      }}
                    >
                      <option value="">Choose...</option>
                      {States &&
                        States.map((value, product_key) => (
                          <option value={value.state_name}>
                            {value.state_name}
                          </option>
                        ))}
                    </select>
                    {!billing_form.state.valid &&
                      billing_form.state.onBlur_out && (
                        <div className="error field-error">
                          {billing_form.state.error_msg}
                        </div>
                      )}
                    <div className="invalid-feedback">
                      Please provide a valid state.
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="zip">Zip</label>
                    <input
                      type={billing_form.zip.type}
                      placeholder={billing_form.zip.placeholder}
                      className="form-control "
                      value={
                        billing_form.zip.value ? billing_form.zip.value : ''
                      }
                      onChange={(event) =>
                        this.inputChangeHandler(event, 'zip')
                      }
                      onBlur={(event) => {
                        billing_form.last_name.onBlur_out = true;
                        this.setState({
                          billing_form: billing_form
                        });
                      }}
                    />
                    {!billing_form.zip.valid && billing_form.zip.onBlur_out && (
                      <div className="error field-error">
                        {billing_form.zip.error_msg}
                      </div>
                    )}
                  </div>
                </div>
                <hr />
                {!form_valid && (
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary btn-lg btn-block mb-5"
                      style={{ width: '300px' }}
                      type="submit"
                    >
                      Continue to checkout
                    </button>
                  </div>
                )}
              </form>

              {form_valid && (
                <>
                  <h4 className="mb-3 payment">Shipping Services</h4>

                  <div className="col-md-12 mb-3 mt-5">
                    <select
                      className="form-select d-block w-100"
                      value={serviceType}
                      onChange={this.handleServiceTypeChange}
                    >
                      <option value="">Select Service</option>
                      <option value="vip">Standard Shipping</option>
                      <option value="self-service">Self-Service</option>
                    </select>

                    {serviceType === 'vip' && (
                      <div className="mt-3">
                        <div className="custom-control custom-radio mb-2">
                          <input
                            type="radio"
                            id="sea-service"
                            name="service"
                            value="sea"
                            checked={selectedService === 'sea'}
                            onChange={this.handleChange}
                            className="custom-control-input"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="sea-service"
                          >
                            &nbsp; Sea Service (~48-60 days) 
				<span className="text-muted">(Cheaper)</span>
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="air-service"
                            name="service"
                            value="air"
                            checked={selectedService === 'air'}
                            onChange={this.handleChange}
                            className="custom-control-input"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="air-service"
                          >
                            &nbsp; Air Service (~7-10 days)
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {form_valid && serviceType && (
                <>
                  <hr className="mb-4" />
                  <h4 className="mb-3 payment">Payment</h4>
                  <div className="d-block my-3">
                    <div className="custom-control custom-radio payments">
                      <input type="radio" id="paystack" checked />
                      <label className="custom-control-label" htmlFor="paystack">
                        <img src="/assets/images/paystack.svg" alt="paystack" style={{ height: '24px' }} />
                      </label>
                    </div>
                  </div>
                  <PaystackButton
                    text="Make a Payment"
                    className="btn btn-primary btn-lg btn-block mb-5 payments-btn"
                    onSuccess={(reference) => this.handleSuccess(reference)}
                    close={this.onClose}
                    reference={this.state.reference}
                    email={billing_form.email.value}
                    amount={parseInt(total) * 100}
                    publicKey={key}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error
  };
}
const mapDispatchToProps = {
  onCart: Actions.GetCart,
  onWishlist: Actions.GetWishlist,
  ShippingCost: Actions.ShippingCost,
  onCheckoutSub: Actions.OrderCreate,
  onAddress: Actions.AddAddress,
  getAddress: Actions.GetAddress,
  onState: Actions.getState,
  onGetAuthUser: userActions.GetAuthUser,
  getDeliveryMods: Actions.GetDeliveryModes,
  onGetproduct: userActions.Getproduct
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);