import React from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import LoginHeader from "../../../components/LoginHeader";
import LoginFooter from "../../../components/LoginFooter";
import Loader from "../../../components/Utility/Loader";
import ReactPaginate from "react-paginate";
import TranslateComponent from "../../Utility/TranslateComponent";
import getSymbolFromCurrency from "currency-symbol-map";
import axios from "./../../../axios";
import SearchInput from "../../SearchInput";

// Font Awesome Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faArrowUp, faArrowDown, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        title: "Title",
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zero_product: false,
      currentPage: 1,
      product_details: [],
      header_search_loader: false,
      ApiCurrency: getSymbolFromCurrency("RMB"),
      ConversionCurrency: getSymbolFromCurrency("NGN"),
      currencyRate: null,
      searchKeyword: null,
      searchInput: "",
      pages: [1],
      sortOrder: "default",
    };
  }

  componentWillMount() {
    if (this.props.location?.state?.results?.length > 0) {
      console.log("in if component mount");
      this.setState({
        product_details: this.props.location?.state?.results,
        currencyRate: localStorage.getItem("currencyRate"),
        searchKeyword: this.props.location.search.substring(1),
      }, this.applySort);
    } else {
      this.setState({ loader: true });
      window.scrollTo(0, 0);
      var search = this.props.location.search.substring(1);

      const searchParams = new URLSearchParams(this.props.location.search);
      const imgUrl = searchParams.get("imgUrl");

      console.log("imgUrl ####################", search);

      if (!search) {
        search = "latest";
      }

      if (search) {
        this.props
          .onGetProduct(search, imgUrl)
          .then((response) => {
            let productData = [];
            if (imgUrl) {
              productData = response?.data?.response?.data;
            } else {
              productData = response?.data?.response?.data?.data;
            }

            console.log("###response", response);

            if (response.data.success === true) {
              this.setState({ loader: false });

              if (!imgUrl) {
                window.history.pushState(
                  null,
                  null,
                  `/product-list/search?${search.trim()}`
                );
              }

              let product_details = [];
              if (productData && productData.length > 0) {
                product_details = productData;
              } else if (response.data?.item) {
                product_details = [response.data.item];
              } else {
                this.setState({ zero_product: true });
              }

              this.setState({
                zero_product: product_details.length === 0,
                product_details: product_details,
                currencyRate: localStorage.getItem("currencyRate"),
                searchKeyword: this.props.location.search.substring(1),
              }, this.applySort);
            } else {
              this.setState({ loader: false });
              toast.error("No results found with this text", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching product data:", error);
            this.setState({ loader: false });
            toast.error("An error occurred while fetching product data", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        console.log("search", search);
        toast.error("Please add some text or link to search items", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ loader: true });
      }
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const searchKeyword = params.get("search") || "";
    const page = parseInt(params.get("page")) || 1;
    this.setState({
      searchKeyword: searchKeyword,
      currentPage: page,
    });

    if (searchKeyword) {
      this.GetProducts(page, searchKeyword);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.state !== prevProps.location.state) {
      if (this.props.location?.state?.results?.length > 0) {
        this.setState({
          product_details: this.props.location.state.results,
          currencyRate: localStorage.getItem("currencyRate"),
          searchKeyword: this.props.location.search.substring(1),
        }, this.applySort);
      }
    }
  }

  GetProducts = (pageNo, product_search) => {
    if (!product_search) {
      toast.error("Please add some text or link to search items", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    this.props
      .onGetProduct(product_search, pageNo)
      .then((response) => {
        if (response?.data?.success) {
          const productData = response?.data?.response?.data?.data || [];
          this.setState({
            zero_product: productData.length === 0,
            product_details: productData,
            currencyRate: localStorage.getItem("currencyRate"),
            loader: false,
          }, this.applySort);
        } else {
          toast.error("No results found with this text", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        toast.error("An error occurred while fetching products.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ loader: false });
      });
  };

  handlePageChange = (direction) => {
    const { currentPage, searchKeyword } = this.state;
    const nextPage = direction === "next" ? currentPage + 1 : currentPage - 1;

    if (nextPage < 1) return;

    this.setState({ loader: true, currentPage: nextPage }, () => {
      this.GetProducts(nextPage, searchKeyword);
      const queryString = `search=${searchKeyword}&page=${nextPage}`;
      window.history.pushState(null, null, `/product-list?${queryString}`);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  };

  fetchData = (page) => {
    console.log(`Fetching data for page: ${page}`);
    return page;
  };

  renderPagination = () => {
    const { pages, currentPage } = this.state;

    return pages.map((page) => (
      <button
        key={page}
        className={`btn mx-0.5 ${
          page === currentPage ? "active" : "page-link"
        }`}
        onClick={() =>
          this.setState({ currentPage: page }, () => this.fetchData(page))
        }
      >
        {page}
      </button>
    ));
  };

  SearchHandler = async (value) => {
    if (value === "") {
      toast.error("Keyword is required to search", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const keyword = value || "iphone";

    this.setState({
      search_loader: true,
      currentPage: 1,
    });

    try {
      this.setState({ loader: true });
      const response = await axios.get(`/item_search?keyword=${keyword}`);
      this.setState({ loader: false });
      console.log("response", response.data.response.data);

      if (response.data) {
        this.setState({
          is_redirect: true,
          redirect_url: `/product-list/search?keyword=${keyword}&page=1`,
          redirect_state: { results: response?.data?.response?.data?.data },
          currentPage: 1,
          product_details: response?.data?.response?.data?.data,
          searchKeyword: keyword,
        });
      } else {
        toast.error("No Result Found", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error occurred during search:", error);
      toast.error("An error occurred during search", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      this.setState({ search_loader: false });
    }
  };

  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value });
  };

  handleSortChange = (sortOrder) => {
    this.setState({ sortOrder }, this.applySort);
  };

  applySort = () => {
    const { product_details, currencyRate, sortOrder } = this.state;
    if (!product_details || product_details.length === 0 || !currencyRate) return;

    const sortedProducts = [...product_details];
    if (sortOrder === "lowToHigh") {
      sortedProducts.sort((a, b) => (a.price * currencyRate) - (b.price * currencyRate));
    } else if (sortOrder === "highToLow") {
      sortedProducts.sort((a, b) => (b.price * currencyRate) - (a.price * currencyRate));
    }

    this.setState({ product_details: sortedProducts });
  };

  render() {
    const {
      product_details,
      zero_product,
      ApiCurrency,
      currencyRate,
      ConversionCurrency,
      searchKeyword,
      searchInput,
      sortOrder,
    } = this.state;

    const currentPageData = product_details;

    return (
      <React.Fragment>
        {this.state.is_redirect ? (
          <Navigate
            to={this.state.redirect_url}
            state={this.state.redirect_state}
          />
        ) : null}
        {this.state.loader ? <Loader /> : null}
        {this.props.loader ? <Loader /> : null}
        <div className="">
          <div className="row my-8 searchDetails">
            <SearchInput />
          </div>

          <div className="category-sec">
            <div className="container-fluid">
              <div className="row mb-4">
                <div className="col-12">
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className={`btn ${sortOrder === "default" ? "btn-primary" : "btn-outline-primary"}`}
                      onClick={() => this.handleSortChange("default")}
                    >
                      <FontAwesomeIcon icon={faList} className="me-2" /> Default
                    </button>
                    <button
                      type="button"
                      className={`btn ${sortOrder === "lowToHigh" ? "btn-primary" : "btn-outline-primary"}`}
                      onClick={() => this.handleSortChange("lowToHigh")}
                    >
                      <FontAwesomeIcon icon={faArrowUp} className="me-2" /> Low to High
                    </button>
                    <button
                      type="button"
                      className={`btn ${sortOrder === "highToLow" ? "btn-primary" : "btn-outline-primary"}`}
                      onClick={() => this.handleSortChange("highToLow")}
                    >
                      <FontAwesomeIcon icon={faArrowDown} className="me-2" /> High to Low
                    </button>
                  </div>
                </div>
              </div>

              <div className="row" id="currentPageData">
                {zero_product &&
                  "NO RESULT FOUND PLEASE TRY AGAIN WITH OTHER KEYWORDS"}
                {currentPageData &&
                  currentPageData.map((item, product_key) => (
                    <div className="col-lg-2 col-md-6" key={product_key}>
                      <div className="product-card">
                        <Link
                          to={`/product-detail/search?${item?.item_id}`}
                          state={{
                            product_detail: item,
                            searchKeyword,
                            currencyRate,
                          }}
                          className="product-card__link"
                        >
                          <div className="product-card__image-container">
                            <img
                              src={item?.main_image_url}
                              alt={item?.title || "Product Image"}
                              className="product-card__image"
                            />
                          </div>
                          <div className="product-card__content">
                            <h2 className="product-card__title">
                              {item?.multi_language_info.title}
                            </h2>
                            <div className="product-card__price">
                              <span className="#product-card__conversion card__amount">
                                {ConversionCurrency}
                                {parseFloat(
                                  item?.price * currencyRate
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                <div className="pagination">
                  <button
                    className="btn page-link"
                    onClick={() => this.handlePageChange("prev")}
                    disabled={this.state.currentPage === 1}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Previous
                  </button>
                  {this.renderPagination()}
                  <button
                    className="btn page-link"
                    onClick={() => this.handlePageChange("next")}
                  >
                    Next <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error,
  };
}

const mapDispatchToProps = {
  onGetProduct: userActions.Getproduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);