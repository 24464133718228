import React from "react";
import { Collapse, Button } from 'react-bootstrap';
import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import './HowToBuy.css'; // Ensure this file is in the same folder

class FAQ extends React.Component {
  state = {
    openSection: null, // Keeps track of which section is currently open
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  toggleSection = (section) => {
    this.setState({
      openSection: this.state.openSection === section ? null : section
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="privacy-policy">
          <div className="top-content policy-bg">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-heading">
                    <h1>Frequently Asked Questions</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="policy-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-12">

                  {/* Section 1: What shipping options do you offer? */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section1')}
                      aria-controls="section1"
                      aria-expanded={this.state.openSection === 'section1'}
                    >
                      <strong>1. What shipping options do you offer?</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section1'}>
                      <div id="section1" className="policy-content">
                        <p>
                          We offer various shipping methods to suit your needs, including standard, expedited, and express shipping. Shipping times and costs vary based on your location and the weight of your order. You will be able to choose your preferred shipping option at checkout.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 2: How long will it take for my order to arrive? */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section2')}
                      aria-controls="section2"
                      aria-expanded={this.state.openSection === 'section2'}
                    >
                      <strong>2. How long will it take for my order to arrive?</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section2'}>
                      <div id="section2" className="policy-content">
                        <p>
                          Delivery times depend on your location and the shipping method you choose. Typically, orders to Nigeria can take anywhere from 7 to 21 days, depending on the shipping option and customs clearance. You will receive a tracking number once your order is shipped, allowing you to monitor its progress.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 3: Do you ship internationally? */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section3')}
                      aria-controls="section3"
                      aria-expanded={this.state.openSection === 'section3'}
                    >
                      <strong>3. Do you ship internationally?</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section3'}>
                      <div id="section3" className="policy-content">
                        <p>
                          Yes, we ship from China to Nigeria and other international locations. Please check our website for available countries and shipping fees during checkout.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 4: How can I track my order? */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section4')}
                      aria-controls="section4"
                      aria-expanded={this.state.openSection === 'section4'}
                    >
                      <strong>4. How can I track my order?</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section4'}>
                      <div id="section4" className="policy-content">
                        <p>
                          Once your order has shipped, you will receive an email with tracking information. You can track your order through the courier's website using the provided tracking number.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 5: What should I do if my order is damaged or missing items? */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section5')}
                      aria-controls="section5"
                      aria-expanded={this.state.openSection === 'section5'}
                    >
                      <strong>5. What should I do if my order is damaged or missing items?</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section5'}>
                      <div id="section5" className="policy-content">
                        <p>
                          If your order arrives damaged or if you are missing any items, please contact our customer service team within 7 days of receiving the package. Provide your order number and a description or photo of the issue, and we will work with you to resolve the problem promptly.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FAQ;