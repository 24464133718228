import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../../components/Utility/Loader';

const SignIn = () => {
    const { loginWithRedirect, isLoading, error, isAuthenticated } = useAuth0();

    useEffect(() => {
        loginWithRedirect();
    }, [loginWithRedirect]);

    return (
        <div>
            {isLoading ? <Loader /> : null}
        </div>
    );
};

export default SignIn;

