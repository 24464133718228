import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as userActions from '../../../actions/index';
import { Navigate } from 'react-router-dom';
import Loader from '../../../components/Utility/Loader';
import * as userActionsCart from '../../../actions/account';
import Validations from '../../../components/Utility/Validations';

class CurrencyConverter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currency_form: {
        currency_rate: {
          type: 'text',
          value: '',
          valid: true,
          error_msg: '',
          onBlur_out: false,
          placeholder: 'Enter your Currency Rate',
          validations: { required: true }
        }
      },
      Unauthorized: false,
      updateForm: false,
      updateFormId: null,
      exist_currency_rate: null,
      forgot_password_modal: false
    };
  }

  componentWillMount() {
    this.props.GetCurrencyRate().then((response) => {
      if (response.success === true) {
        console.log(response.data.currency_rate, '#########################');
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        this.setState({
          exist_currency_rate: response.data?.currency_rate,
          updateForm: true,
          updateFormId: response.data?.id
        });
      } else {
        if (response.message == 'Unauthorized') {
          this.setState({
            Unauthorized: true
          });
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      }
    });
  }

  inputChangeHandler(event, identifier) {
    if (identifier === 'currency_rate') {
      this.setState({ exist_currency_rate: null });
    }
    const update_currency_form = { ...this.state.currency_form };
    const form_element = { ...update_currency_form[identifier] };
    form_element.value = event.target.value;

    // Check Validation
    let validate_element = Validations(event, form_element);
    form_element.valid = validate_element.valid;
    form_element.error_msg = validate_element.error_msg;

    update_currency_form[identifier] = form_element;

    this.setState({ currency_form: update_currency_form });
  }

  SubmitHandler = (event) => {
    event.preventDefault();
    let is_form_valid = true;
    const update_currency_form = this.state.currency_form;
    for (let key in update_currency_form) {
      let form_element = update_currency_form[key];

      console.log(form_element);
      let validate_element = Validations(event, form_element);

      is_form_valid = validate_element.valid === false ? false : is_form_valid;
      form_element.onBlur_out = true;
      form_element.valid = validate_element.valid;
      form_element.error_msg = validate_element.error_msg;

      update_currency_form[key] = form_element;
    }
    this.setState({ currency_form: update_currency_form });

    if (is_form_valid) {
      const form_data = new FormData();
      if (this.state.updateFormId) {
        form_data.append('id', this.state.updateFormId);
      }
      for (let key in this.state.currency_form) {
        form_data.append(key, this.state.currency_form[key].value);
      }
      this.props.SetCurrencyRate(form_data).then((response) => {
        console.log(response, 'jhsjkdhskjfhsjk');
        if (response.success === true) {
          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        } else {
          if (response.message == 'Unauthorized') {
            this.setState({
              Unauthorized: true
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
        }
      });
    }
  };

  render() {
    const { currency_form, exist_currency_rate, Unauthorized, updateForm } =
      this.state;
    return (
      <React.Fragment>
        <ToastContainer />
        {/* {Unauthorized ? <Navigate to={"/admin-login"}/> : null} */}
        {this.state.loader ? <Loader /> : null}
        {this.props.loader ? <Loader /> : null}

        <section className="home-section">
          <nav>
            <div className="sidebar-button">
              <i className="bx bx-menu sidebarBtn"></i>
              <span className="dashboard">Currency Exchange</span>
            </div>
          </nav>
          <div className="">
            <div className="currency-sec">
              <div className="conatiner-fluid">
                <div className="row">
                  <div className="currency-form">
                    <div className="col-md-4">
                      <div className="row">
                        <div className="field-sec">
                          <form onSubmit={this.SubmitHandler}>
                            <h3 className="sing_in">Currency Rate</h3>
                            <div className="form-group">
                              <input
                                type={currency_form.currency_rate.type}
                                className="form-control form-control-lg form-control-solid"
                                placeholder={
                                  currency_form.currency_rate.placeholder
                                }
                                value={
                                  exist_currency_rate
                                    ? exist_currency_rate
                                    : currency_form.currency_rate.value
                                    ? currency_form.currency_rate.value
                                    : ''
                                }
                                onChange={(event) =>
                                  this.inputChangeHandler(
                                    event,
                                    'currency_rate'
                                  )
                                }
                                onBlur={(event) => {
                                  currency_form.currency_rate.onBlur_out = true;
                                  this.setState({
                                    currency_form: currency_form
                                  });
                                }}
                              />
                              {!currency_form.currency_rate.valid &&
                                currency_form.currency_rate.onBlur_out && (
                                  <div className="error field-error">
                                    {currency_form.currency_rate.error_msg}
                                  </div>
                                )}
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary sign-in"
                            >
                              {updateForm ? 'Update' : 'Submit'}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error
  };
}
const mapDispatchToProps = {
  SetCurrencyRate: userActionsCart.SetCurrencyRate,
  GetCurrencyRate: userActionsCart.GetCurrencyRate
};
export default connect(mapStateToProps, mapDispatchToProps)(CurrencyConverter);
