import React from "react";
import { Link } from "react-router-dom";

class LoginFooter extends React.Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-logo">
                                    <Link href="/">
                                        <img src="/assets/images/site-logo.png" className="logo-img" alt="logo" />
                                    </Link>
                                </div>
                                <div className="footer-content">
<p>At Flyorship, we revolutionize cross-border shopping for Nigerian consumers, Nigerians in the diaspora, and Chinese living in Nigeria by providing seamless access to leading Chinese e-commerce platforms, offering a wide range of products from fashion to electronics and more.</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className="quick-link">
                                    <h3>Quick Links</h3>
                                    <ul>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/terms-condition">Terms &amp; Conditions</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className="company-info">
                                    <h3>Company</h3>
                                    <ul>
                                        <li><Link to="/about-us">About Us</Link></li>
                                        <li><Link to="/how-to-buy">How To Buy</Link></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                        <li><Link to="/faq">FAQ</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className="social-link">
                                    <h3>Social Media</h3>
                                    <ul>
                                        <li><Link to="#"><i className="fab fa fa-facebook-f"></i></Link></li>
                                        <li><Link to="https://x.com/flyorship"><i className="fab fa fa-twitter"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/flyorship/"><i className="fab fa fa-instagram"></i></Link></li>
                                        {/* <li><Link to="#"><i className="fab fa fa-google"></i></Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className="company-info">
                                    <ul>
                                        {/* <li>
                                            <Link to="//wa.me/1234567890" target="_blank">
                                                https://wa.me/+1234567890
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="copyright-sec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="content">
                                    <p className="text-start mx-3">Copyright &copy; 2025. All Rights Reserved Flyorship</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content">
                                    {/* <p className="text-end mx-3">Supported by : <a className="text-white" href="https://otcommerce.com/">otcommerce.com</a></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LoginFooter;