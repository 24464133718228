import React, { useState } from "react";
import axios from "./../axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const SearchInput = () => {
  const [searchInput, setSearchInput] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const searchHandler = async (value) => {
    if (value === "" && !selectedFile) {
      toast.error("Keyword is required to search", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setSearchLoader(true);

    try {
      let response = null;
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        response = await axios.post(`/image_search`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const url = new URL("/product-list/search", window.location.origin);
        url.searchParams.set("imgUrl", response?.data?.filePath);
        window.location.href = url.toString();
      } else {
        response = await axios.get(`/item_search?keyword=${value}`);
        navigate(`/product-list/search?${value}`, {
          state: { results: response?.data?.response?.data?.data },
        });
      }

      if (!response || !response.data) {
        toast.error("No Result Found", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error occurred during search:", error);
      toast.error("An error occurred during search", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setSearchLoader(false);
    }
  };

  const handleSearchInputChange = (event) => {
    setImagePreview(null);
    setSelectedFile(null);
    setSearchInput(event.target.value);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type.startsWith("image/")) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
          toast.success("Image added successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        };
        reader.readAsDataURL(file);
      } else {
        setSelectedFile(null);
        toast.error("Only image files are allowed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file" && item.type.startsWith("image/")) {
        const file = item.getAsFile();
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
          toast.success("Image pasted successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type.startsWith("image/")) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
          toast.success("Image added successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        };
        reader.readAsDataURL(file);
      } else {
        setSelectedFile(null);
        toast.error("Only image files are allowed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleClose = () => {
    setSelectedFile(null);
    setImagePreview(null);
  };

  const isSearchDisabled = !searchInput && !selectedFile;

  return (
    <div className="row">
      <div className="col-12">
        <div
          className="col-12 position-relative"
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          onPaste={handlePaste}
          style={{
            maxWidth: "800px", // Wider input as previously set
            margin: "0 auto",
          }}
        >
          <input
            type="text"
            value={searchInput}
            onChange={handleSearchInputChange}
            placeholder="Search text or image"
            className="form-control"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !isSearchDisabled) {
                searchHandler(searchInput);
              }
            }}
            style={{
              borderRadius: "25px",
              border: "1px solid #ced4da",
              padding: "12px 60px 12px 20px", // Increased right padding from 50px to 60px
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              fontSize: "16px",
              transition: "all 0.3s ease",
              background: "#fff",
            }}
            onFocus={(e) => {
              e.target.style.borderColor = "#124e75";
              e.target.style.boxShadow = "0 4px 12px rgba(18, 78, 117, 0.2)";
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#ced4da";
              e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
            }}
          />
          {searchLoader ? (
            <div
              className="position-absolute top-50 end-0 translate-middle-y me-3"
              style={{ right: "25px" }} // Adjusted from 20px to 25px to match padding
            >
              <div className="spinner-border text-primary" role="status" style={{ width: "20px", height: "20px" }}>
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div
              className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted d-flex"
              style={{ right: "15px" }} // Adjusted from 10px to 15px to match padding
            >
              <i
                className="fa fa-search"
                style={{
                  cursor: isSearchDisabled ? "not-allowed" : "pointer",
                  padding: "10px",
                  color: isSearchDisabled ? "#ccc" : "#124e75",
                  fontSize: "18px",
                  transition: "color 0.3s ease",
                }}
                onClick={() => !isSearchDisabled && searchHandler(searchInput)}
                onMouseEnter={(e) => !isSearchDisabled && (e.target.style.color = "#0d3a57")}
                onMouseLeave={(e) => !isSearchDisabled && (e.target.style.color = "#124e75")}
              />
              <i
                className="fa fa-upload"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  color: "#124e75",
                  fontSize: "18px",
                  transition: "color 0.3s ease",
                }}
                onClick={() => document.getElementById("fileInput").click()}
                onMouseEnter={(e) => (e.target.style.color = "#0d3a57")}
                onMouseLeave={(e) => (e.target.style.color = "#124e75")}
              />
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
            </div>
          )}
        </div>
        {imagePreview && (
          <div className="mt-3 d-flex justify-content-center">
            <div
              className="img-div position-relative"
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "15px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
                background: "#fff",
              }}
            >
              <div
                className="position-absolute"
                style={{ top: "5px", right: "5px" }}
              >
                <i
                  className="fa fa-times"
                  style={{
                    cursor: "pointer",
                    padding: "8px",
                    color: "#124e75",
                    fontSize: "20px",
                    background: "rgba(255, 255, 255, 0.8)",
                    borderRadius: "50%",
                    transition: "background 0.3s ease",
                  }}
                  onClick={handleClose}
                  onMouseEnter={(e) => (e.target.style.background = "rgba(255, 255, 255, 1)")}
                  onMouseLeave={(e) => (e.target.style.background = "rgba(255, 255, 255, 0.8)")}
                />
              </div>
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchInput;