import React from "react";
import { Collapse, Button } from 'react-bootstrap';
import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import './HowToBuy.css';  // Ensure this file is in the same folder

class HowToBuy extends React.Component {
  state = {
    openSection: null, // Keeps track of which section is currently open
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  toggleSection = (section) => {
    this.setState({
      openSection: this.state.openSection === section ? null : section
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="how-to-buy-container">
          <div className="top-content policy-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-heading">
                    <h1>Terms & Conditions</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="policy-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {/* <h2>Legal Terms & Conditions</h2> *}

                  {/* Section 1: Agreement to Terms */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section1')}
                      aria-controls="section1"
                      aria-expanded={this.state.openSection === 'section1'}
                    >
                      <strong>1. Agreement to Terms</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section1'}>
                      <div id="section1" className="policy-content">
                        <p>
                          By accessing and using Flyorship website and services, you agree to be bound by these Terms and Conditions.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 2: Services Description */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section2')}
                      aria-controls="section2"
                      aria-expanded={this.state.openSection === 'section2'}
                    >
                      <strong>2. Services Description</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section2'}>
                      <div id="section2" className="policy-content">
                        <p>
                          Flyorship provides an e-commerce platform enabling customers to:
                        </p>
                        <ul>
                          <li>Purchase products from Chinese e-commerce websites</li>
                          <li>Choose between Self-Service and VIP-Service shipping options</li>
                          <li>Track orders and manage deliveries</li>
                          <li>Make secure international payments</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 3: Account Registration */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section3')}
                      aria-controls="section3"
                      aria-expanded={this.state.openSection === 'section3'}
                    >
                      <strong>3. Account Registration</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section3'}>
                      <div id="section3" className="policy-content">
                        <h6>3.1 Requirements</h6>
                        <ul>
                          <li>Must be 18 years or older</li>
                          <li>Provide accurate and complete information</li>
                          <li>Maintain current contact information</li>
                          <li>Protect account credentials</li>
                          <li>Accept responsibility for account activity</li>
                        </ul>
                        <h6>3.2 Verification</h6>
                        <p>
                          - Government-issued ID required. <br />
                          - Additional verification may be requested. <br />
                          - We reserve the right to suspend accounts pending verification.
                        </p>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 4: Service Options */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section4')}
                      aria-controls="section4"
                      aria-expanded={this.state.openSection === 'section4'}
                    >
                      <strong>4. Service Options</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section4'}>
                      <div id="section4" className="policy-content">
                        <h6>4.1 Self-Service</h6>
                        <ul>
                          <li>Customer is responsible for logistics arrangements after delivery to their chosen freight company.</li>
                          <li>Flyorship responsibility ends upon delivery to the customer specified logistics company.</li>
                          <li>Shipping costs to logistics company are included.</li>
                        </ul>
                        <h6>4.2 VIP-Service</h6>
                        <ul>
                          <li>Complete procurement and delivery service.</li>
                          <li>Fixed rate shipping fees.</li>
                          <li>Customs clearance assistance.</li>
                          <li>Additional services subject to fees as outlined in pricing structure.</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 5: Pricing and Payments */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section5')}
                      aria-controls="section5"
                      aria-expanded={this.state.openSection === 'section5'}
                    >
                      <strong>5. Pricing and Payments</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section5'}>
                      <div id="section5" className="policy-content">
                        <ul>
                          <li>All prices displayed in customer local currency</li>
                          <li>Service charges apply as per current rate structure</li>
                          <li>Payment methods: Credit/debit cards, bank transfers, cryptocurrency</li>
                          <li>Processing fees may apply</li>
                          <li>All payments must clear before order processing</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 6: Shipping and Delivery */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section6')}
                      aria-controls="section6"
                      aria-expanded={this.state.openSection === 'section6'}
                    >
                      <strong>6. Shipping and Delivery</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section6'}>
                      <div id="section6" className="policy-content">
                        <h6>6.1 Timeframes</h6>
                        <ul>
                          <li>Delivery times are estimates only</li>
                          <li>Not responsible for customs or shipping delays</li>
                          <li>Force majeure events may affect delivery</li>
                        </ul>
                        <h6>6.2 VIP-Service Specific</h6>
                        <ul>
                          <li>Demurrage charges apply after 5 days</li>
                          <li>Additional packaging costs included</li>
                          <li>Customs clearance assistance provided</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 7: Order Cancellation and Refunds */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section7')}
                      aria-controls="section7"
                      aria-expanded={this.state.openSection === 'section7'}
                    >
                      <strong>7. Order Cancellation and Refunds</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section7'}>
                      <div id="section7" className="policy-content">
                        <ul>
                          <li>Cancellation available before order processing</li>
                          <li>Refund policies vary by payment method</li>
                          <li>Processing fees non-refundable</li>
                          <li>Shipping costs non-refundable once incurred</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 8: User Responsibilities */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section8')}
                      aria-controls="section8"
                      aria-expanded={this.state.openSection === 'section8'}
                    >
                      <strong>8. User Responsibilities</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section8'}>
                      <div id="section8" className="policy-content">
                        <ul>
                          <li>Provide accurate information</li>
                          <li>Comply with customs regulations</li>
                          <li>Pay applicable taxes and duties</li>
                          <li>Maintain appropriate licenses for imports</li>
                          <li>Use platform for legitimate business purposes</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 9: Prohibited Activities */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section9')}
                      aria-controls="section9"
                      aria-expanded={this.state.openSection === 'section9'}
                    >
                      <strong>9. Prohibited Activities</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section9'}>
                      <div id="section9" className="policy-content">
                        <ul>
                          <li>Fraudulent transactions</li>
                          <li>Unauthorized access attempts</li>
                          <li>Purchase of illegal goods</li>
                          <li>Violation of export/import laws</li>
                          <li>Misuse of platform features</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 10: Intellectual Property */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section10')}
                      aria-controls="section10"
                      aria-expanded={this.state.openSection === 'section10'}
                    >
                      <strong>10. Intellectual Property</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section10'}>
                      <div id="section10" className="policy-content">
                        <ul>
                          <li>All content is property of Flyorship</li>
                          <li>Limited license for personal use</li>
                          <li>No commercial use without permission</li>
                          <li>Trademarks and logos protected</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 11: Limitation of Liability */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section11')}
                      aria-controls="section11"
                      aria-expanded={this.state.openSection === 'section11'}
                    >
                      <strong>11. Limitation of Liability</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section11'}>
                      <div id="section11" className="policy-content">
                        <ul>
                          <li>Not responsible for product quality from suppliers</li>
                          <li>Limited liability for shipping delays</li>
                          <li>No liability for customs issues</li>
                          <li>Force majeure exclusions apply</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 12: Dispute Resolution */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section12')}
                      aria-controls="section12"
                      aria-expanded={this.state.openSection === 'section12'}
                    >
                      <strong>12. Dispute Resolution</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section12'}>
                      <div id="section12" className="policy-content">
                        <ul>
                          <li>Good faith resolution attempt required</li>
                          <li>Applicable law: [Jurisdiction]</li>
                          <li>Mandatory arbitration</li>
                          <li>Class action waiver</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 13: Platform Availability */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section13')}
                      aria-controls="section13"
                      aria-expanded={this.state.openSection === 'section13'}
                    >
                      <strong>13. Platform Availability</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section13'}>
                      <div id="section13" className="policy-content">
                        <ul>
                          <li>No guarantee of continuous availability</li>
                          <li>Maintenance windows as needed</li>
                          <li>Right to modify features</li>
                          <li>Right to terminate services</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 14: Changes to Terms */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section14')}
                      aria-controls="section14"
                      aria-expanded={this.state.openSection === 'section14'}
                    >
                      <strong>14. Changes to Terms</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section14'}>
                      <div id="section14" className="policy-content">
                        <ul>
                          <li>Right to modify terms</li>
                          <li>Notice of material changes provided</li>
                          <li>Continued use constitutes acceptance</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Section 15: Contact Information */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('section15')}
                      aria-controls="section15"
                      aria-expanded={this.state.openSection === 'section15'}
                    >
                      <strong>15. Contact Information</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'section15'}>
                      <div id="section15" className="policy-content">
                        <ul>
                          <li>Email: flyorship@gmail.com</li>
                          <li>Website: www.flyorship.com</li>
                        </ul>
                      </div>
                    </Collapse>
                  </div>

                  {/* Refund Policy Section */}
                  <div className="policy-section">
                    <Button
                      className="policy-button"
                      variant="link"
                      onClick={() => this.toggleSection('refundPolicy')}
                      aria-controls="refundPolicy"
                      aria-expanded={this.state.openSection === 'refundPolicy'}
                    >
                      <strong>Refund Policy</strong>
                    </Button>
                    <Collapse in={this.state.openSection === 'refundPolicy'}>
                      <div id="refundPolicy" className="policy-content">
                        <h6>1. Overview</h6>
                        <p>
                          At Flyorship, we are committed to ensuring customer satisfaction while maintaining fair business practices. This refund policy outlines the conditions and procedures for refunds on our platform.
                        </p>

                        <h6>2. Eligibility for Refunds</h6>
                        <h6>2.1 Before Order Processing</h6>
                        <ul>
                          <li>Full refund available if order has not been processed with suppliers</li>
                          <li>Processing fees deducted from refund amount</li>
                          <li>Refund processed using original payment method</li>
                          <li>Processing time: 5-10 business days</li>
                        </ul>

                        <h6>2.2 After Order Processing, Before Shipping</h6>
                        <ul>
                          <li>Partial refund available (70% of product cost)</li>
                          <li>Service charges and processing fees non-refundable</li>
                          <li>Supplier restocking fees may apply</li>
                          <li>Processing time: 10-15 business days</li>
                        </ul>

                        <h6>2.3 During Shipping</h6>
                        <p><strong>Self-Service Orders:</strong></p>
                        <ul>
                          <li>No refund once goods reach customer designated logistics company</li>
                          <li>Shipping fees non-refundable</li>
                        </ul>
                        <p><strong>Standard-Service Orders:</strong></p>
                        <ul>
                          <li>Partial refund possible if goods are lost (subject to investigation)</li>
                          <li>Insurance claims processed as per shipping partner policies</li>
                          <li>Customs clearance fees non-refundable</li>
                        </ul>

                        <h6>3. Non-Refundable Items and Services</h6>
                        <ul>
                          <li>Service charges</li>
                          <li>Payment processing fees</li>
                          <li>Shipping costs once incurred</li>
                          <li>Customs clearance fees</li>
                          <li>Demurrage charges</li>
                          <li>VIP service fees after service initiation</li>
                        </ul>

                        <h6>4. Refund Process</h6>
                        <h6>4.1 How to Request a Refund</h6>
                        <strong>To request a refund for your Flyorship order, 
                        please email us at flyorship@gmail.com with 
                        the following information: </strong>
                        <ul>
                            <li>Your order number</li>
                            <li>Reason for refund request</li>
                            <li>Your full name as registered on Flyorship</li>
                            <li>Screenshots or photos (if applicable)</li>                         
                        </ul>
                        <h6>4.2 Required Documentation</h6>
                        <ul>
                          <li>Order number</li>
                          <li>Reason for refund</li>
                          <li>Supporting evidence (if applicable)</li>
                          <li>Payment confirmation</li>
                          <li>Any relevant communication with customer service</li>
                        </ul>

                        <h6>5. Payment of Refunds</h6>
                        <h6>5.1 Refund Methods</h6>
                        <ul>
                          <li>Original payment method used for purchase</li>
                          <li>Bank transfer (if original payment method unavailable)</li>
                          <li>Store credit (optional)</li>
                        </ul>
                        <h6>5.2 Processing Times</h6>
                        <ul>
                          <li>Credit/Debit Cards: 5-10 business days</li>
                          <li>Bank Transfers: 7-14 business days</li>
                          <li>Cryptocurrency: 3-5 business days</li>
                          <li>Store Credit: Immediate</li>
                        </ul>

                        <h6>6. Special Circumstances</h6>
                        <h6>6.1 Lost or Damaged Goods</h6>
                        <ul>
                          <li>Must be reported within 24 hours of delivery</li>
                          <li>Photo evidence required for damaged goods</li>
                          <li>Insurance claims processed as per carrier policies</li>
                          <li>Resolution time: 15-30 business days</li>
                        </ul>
                        <h6>6.2 Failed Deliveries</h6>
                        <ul>
                          <li>Full investigation conducted</li>
                          <li>Refund decision based on investigation results</li>
                          <li>Processing time: 20-30 business days</li>
                        </ul>
                        <h6>6.3 Cancelled Orders</h6>
                        <ul>
                          <li>Full refund if cancelled before processing</li>
                          <li>Partial refund if cancelled during processing</li>
                          <li>No refund if cancelled after shipping</li>
                        </ul>

                        <h6>7. Disputes and Appeals</h6>
                        <ul>
                          <li>Submit appeals within 30 days of refund decision</li>
                          <li>Provide additional documentation as requested</li>
                          <li>Final decision made within 15 business days</li>
                          <li>Management reserves right for final decision</li>
                        </ul>

                        <h6>8. Contact Information</h6>
                        <p>For refund-related queries:</p>
                        <ul>
                          <li>Email: flyorship@gmail.com</li>
                        </ul>

                        <h6>9. Policy Updates</h6>
                        <p>
                          We reserve the right to modify this refund policy at any time. Changes will be posted on our website and will be effective immediately upon posting.
                        </p>
                        <p>
                          <em>
                            Note: This refund policy is subject to the terms and conditions of Flyorship's service agreement. For detailed information about our services, please refer to our Terms and Conditions.
                          </em>
                        </p>
                      </div>
                    </Collapse>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HowToBuy;